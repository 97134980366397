.bg-gradient-danger {
    background: linear-gradient(135deg, #ff7a59, #ff9068) !important;
  }
  
  .table-bg-gradient-danger {
    background: linear-gradient(135deg, #ff4b1f, #ff9068) !important;
    color: #fff;
  }
  .table-bg-gradient-danger th, .table-bg-gradient-danger td {
    border-color: rgba(255, 255, 255, 0.1) !important; 
  }
  .bg-gradient-info {
    background: linear-gradient(135deg, #74e1ff, #0072ff) !important;
  }
  
  .table-bg-gradient-info {
    background: linear-gradient(135deg, #00c6ff, #0072ff);
    color: #fff;
  }
  
  .table-bg-gradient-info th, .table-bg-gradient-info td {
    border-color: rgba(255, 255, 255, 0.1);
  }


  .color-liveTable {
    background-color: transparent;
  }
  
  .color-liveTable th, .color-liveTable td {
    background-color: rgba(255, 139, 139, 0.1);
    color: #fff; 
  }

  .color-liveTable  th {
    border-bottom: none;
  }
  
  .color-liveTable  td {
    border-top: none;
    font-size: 20px;
  }

  .color-leadTable {
    background-color: transparent;
  }
  
  .color-leadTable th, .color-leadTable td {
    background-color: rgba(82, 204, 223, 0.15);
    color: #fff; 
  }

  .color-leadTable  th {
    border-bottom: none;
  }
  
  .color-leadTable  td {
    border-top: none;
    font-size: 20px;
  }
  
  .card-custom {
    background: linear-gradient(to right, #e0f7fa, #fce4ec); /* Lighter gradient colors */
    border: 1px solid #ddd; /* Optional: add a border for better visibility */
    color: #333; /* Darker text color for better readability against lighter gradient */
  }
  
  .card-custom .card-body {
    font-size: 1.2rem; 
    padding: 30px;
    padding-bottom : 0px;
  }

  .card-custom .fa {
    color: #fff; /* You can adjust this color if needed */
  }
  
  .card-custom .list-unstyled li {
    font-size: 1.1rem; 
    margin-bottom: 5px;
  }
  
  .card-custom .mdi-note-outline {
    font-size: 1.5rem; 
  }
  