/* iPhone-Style Loader */
.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.692); /* Black background with 30% opacity */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
  }
  
.loader {
    width: 50px;
    aspect-ratio: 1;
    display: grid;
    border-radius: 50%;
    background:
      linear-gradient(0deg ,rgba(255, 255, 255, 0.845) 30%,#0000 0 70%,rgb(36, 35, 35) 0) 50%/8% 100%,
      linear-gradient(90deg,rgba(166, 166, 166, 0.25) 30%,#0000 0 70%,rgb(0 0 0/75% ) 0) 50%/100% 8%;
    background-repeat: no-repeat;
    animation: l23 1s infinite steps(12);
  }
  .loader::before,
  .loader::after {
     content: "";
     grid-area: 1/1;
     border-radius: 50%;
     background: inherit;
     opacity: 0.915;
     transform: rotate(30deg);
  }
  .loader::after {
     opacity: 0.83;
     transform: rotate(60deg);
  }
  @keyframes l23 {
    100% {transform: rotate(1turn)}
  }