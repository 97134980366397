
.modal.quotation-generator{
  --bs-modal-width:2480px;
}

.q_container {
    width: 210mm;
    /* height: 297mm; */
    margin: 0 auto;
    border: 1px solid #000;
    box-sizing: border-box;
    font-family: Arial, sans-serif;
  }

  
  .q_title {
    margin-bottom: 20px;
    color: #3a3086;
  }
  
  .quotation-content {
    /* border: 1px solid #ccc; */
    /* padding: 10px; */
    margin-bottom: 20px;
  }
  
  .quotation-subject {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #3a3086;
  }
  .table-info{
    --bs-table-bg: #3a3086;
    --bs-table-color:white;
  }
