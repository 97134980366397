.bell-button__badge {
    position: absolute;
    top: 5px;
    right: -15px;
    width: 21px;
    height: 21px;
    background: rgb(236, 62, 62);
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 15px;
  }

  .lead_count{
    background: rgb(236, 62, 62);
    color: #ffffff;
    width: 21px;
    height: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 15px;
  }
  