@media only screen and (min-width: 320px) and (max-width: 560px) {
    .content-wrapper {
      padding: 1rem 1rem;
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 560px) {
    .HeaderScreen {
      display: none !important;
    }
 
  }

  @media only screen and (min-width: 320px) and (max-width: 560px) {
    .map {
      height: 30vh !important;
    }
 
  }

  @media only screen and (min-width: 320px) and (max-width: 560px) {
    .card .card-body{
      padding: 1rem 1rem !important;
    }
    
  }

  .offcanvas {
    width: 100% !important;
}

@media only screen and (min-width: 768px) {
    .offcanvas {
        /* On desktop screens (>= 768px), apply w-75 width */
        width: 75% !important;
    }
}


