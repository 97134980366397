.fixed-alerts-bottom-right {
  position: fixed;
  bottom: 0;
  right: 25px;
  width: 300px;
  z-index: 1050;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: column-reverse; 
  gap: 10px; 
}


.scroll-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

.scroll-buttons button {
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 20px;
}
@media (max-width: 768px) {
  .search-results {
    max-height: 200px; 
    overflow-y: auto;
  }

  .search-item {
    font-size: 14px;
    padding: 8px;
  }
}


